import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { ReactComponent as LeftArrow } from '../images/arrow-left-large.svg'
import { HTMLType, StyleType } from '../utils/prop-types'
import Heading from './heading'
import Text from './text'

const ValueHeading = ({ content }) => (
  <Heading
    headingType="h1"
    content={content}
    style={tw`pb-3 font-light text-primary-400 lg:pb-0`}
  />
)

ValueHeading.propTypes = {
  content: HTMLType,
}

const StatisticScroller = ({ style, statistics }) => {
  return (
    <div css={[tw`relative w-full`, style]}>
      <div
        css={tw`h-full overflow-y-visible lg:(w-92 divide-y divide-primary-500 divide-opacity-20)`}
      >
        {statistics.map((stat, i) => (
          <div css={tw`first-of-type:pt-0 pt-10 divide-y lg:(py-12 divide-none)`} key={i}>
            <ValueHeading content={stat.value} />
            <Text content={stat.labelNode} style={tw`pt-3 text-primary-300 lg:mt-4`} />
          </div>
        ))}
      </div>
      <div css={tw`hidden lg:(block absolute inset-y-40 -right-16 ) xl:(right-0)`}>
        <LeftArrow css={tw`sticky top-40`} />
      </div>
    </div>
  )
}

StatisticScroller.propTypes = {
  style: StyleType,
  statistics: PropTypes.arrayOf(PropTypes.object),
}

export default StatisticScroller
