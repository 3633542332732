import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { format } from '../utils/date'
import { isValid as isValidDate, parseISO } from 'date-fns'
import { StyleType } from '../utils/prop-types'
import { getNewsUrl } from '../utils/routing'
import Button from './button'
import Heading from './heading'
import Link from './link'
import Text from './text'

const ArticleCarousel = ({ cards, style }) => {
  const [viewportRef] = useEmblaCarousel({
    loop: false,
    speed: 5,
    align: 'start',
    containScroll: 'trimSnaps',
  })

  return (
    <div
      css={[
        global`layout.container`,
        css`
          ${tw`block overflow-visible`}
          &.is-draggable {
            ${tw`cursor-grab`}
          }
          &.is-dragging {
            ${tw`cursor-grabbing`}
          }
        `,
      ]}
      ref={viewportRef}
    >
      <div css={[tw`flex flex-row flex-nowrap`, style]}>
        {cards.map((card, index) => (
          <div
            key={index}
            css={tw`flex flex-col items-start justify-between flex-none w-full mx-container-mobile lg:(pr-8 ml-8 mr-0 w-116 border-r last:border-r-0) xl:w-140`}
          >
            <div css={tw`flex flex-col items-start`}>
              {card.typeOfArticle ? (
                <>
                  <Text
                    style={tw`hidden mb-2 text-primary-300 lg:block`}
                    content={card.typeOfArticle}
                  />
                  <Text
                    style={tw`mb-2 text-primary-300 lg:hidden`}
                    content={`${card.typeOfArticle} - ${
                      isValidDate(parseISO(card.date))
                        ? format(parseISO(card.date), 'dd MMMM yyyy')
                        : 'Invalid date'
                    }`}
                  />
                </>
              ) : (
                <Text
                  style={tw`mb-2 text-primary-300 lg:hidden`}
                  content={`${
                    isValidDate(parseISO(card.date))
                      ? format(parseISO(card.date), 'dd MMMM yyyy')
                      : 'Invalid date'
                  }`}
                />
              )}
              <Link to={getNewsUrl(card.slug, card.typeOfArticle)}>
                <Heading content={card.headline} headingType="h4" style={tw`font-bold`} />
              </Link>
              <Text
                style={tw`mt-6 text-primary-400`}
                content={card.contentNode.childMarkdownRemark.excerpt}
              />
              <Button
                type="primary"
                size="sm"
                style={css`
                  ${tw`mt-7 lg:hidden`}
                  span {
                    ${tw`ml-0`}
                  }
                `}
                theme="transparent-transparent"
                label="Read more" // TODO: Add into CMS
                link={getNewsUrl(card.slug, card.typeOfArticle)}
              />
            </div>
            <time css={tw`hidden lg:(block mt-24 font-bold)`}>
              {isValidDate(parseISO(card.date))
                ? format(parseISO(card.date), 'dd MMMM yyyy')
                : 'Invalid date'}
            </time>
          </div>
        ))}
      </div>
    </div>
  )
}

ArticleCarousel.defaultProps = {
  cards: [],
}

ArticleCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default ArticleCarousel
